.wrapper {
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.formContainer {
  width: 100%;
  max-width: 420px;
  border-radius: 24px;
  border: none;
  background-color: #ffffff;
  box-shadow: 0px 0px 24px 0px #181A210A, 0px 60px 120px 0px #181A2114;
  padding: 36px;
  z-index: 1;
}

.logo {
  width: 170px;
  height: 30px;
  display: block;
  margin-bottom: 36px;
}

.title {
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  color: #181a21;
  margin-bottom: 24px;
  text-align: left;
}
.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #181a21;
  text-align: center;
  margin-top: 32px;
}
.containerText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgb(245, 6, 6);
  padding: 10px 0;
}

.error {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #E74C3C;
  margin-top: 4px;
}

@media (max-width: 767px) {
  .wrapper {
    align-items: start;
    margin-top: 24px;
  }

  .formContainer {
    padding: 24px;
  }
  .title {
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 32px;
  }
  .text {
    margin-top: 24px;
    font-size: 14px;
    line-height: 20px;
  }
}
