.form {
    margin-bottom: 10px;
}
.pairBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.label {
    opacity: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: block;
    text-align: left;
    color: #848790;
    position: absolute;
    left: 16px;
    top: 2px;
  }
  
  .labelHidden {
    display: none;
  }
  
  .divInput{
    position: relative;
    margin-bottom: 10px;
    width: 50%;
  }

  .input {
    display: block;
    border: none;
    color: #181a21;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    width: 100%;
    background-color: #F6F6F8;
    border-radius: 12px;
    margin-top: 16px
  }
  
  .input::placeholder {
    color: #848790;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  
  .input:focus {
    background-color: #EBECEE
  }
  
  /* .input:active {
    background-color: #EBECEE
  } */
  
  .crossSvg {
    position: absolute;
    top: 4px;
    right: 0;
    cursor: pointer;
  }
  
  .crossSvg:hover path {
    fill: #00BEB4;
  }
  
  .crossSvgHidden {
    display: none;
  }

  .button {
    margin-top: 16px
  }
  
  @media (max-width: 767px) {}