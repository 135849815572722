.main {
  background-color: #ffffff;
  padding-top: 110px;
  height: 100vh;
  overflow-y: hidden;
}

.title {
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 28px;
  FONT-WEIGHT: 700;
}

@media (max-width: 767px) {
  .main {
    padding-top: 93px;
  }
}
