.th {
  padding: 10px;
  min-width: 60px;
  white-space: break-spaces;
}

.tableBox {
  max-width: 1200px;
  margin: 0 auto;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 16px;
  position: relative;
}

.td{
  background-color: #F6F6F8;
}
