.sidebar {
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 90px;
  left: 0;
  z-index: 90;
}

.ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 420px;
  margin: 0 auto;
  background-color: #F6F6F8;
  border: 2px solid #F6F6F8;
  border-radius: 12px;
  /* padding: 20px 0; */
}

.li {
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.li a {
  color: #848790;
}

.liActive a {
  color: #181a21;
}

.li:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .sidebar {
    top: 80px;
  }
  .ul {
    max-width: 398px;
  }
}
