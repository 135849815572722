.varsBlock {
  width: 100%;
  margin-bottom: 12px;
  border-radius: 10px;
  box-shadow: 0px 0px 24px 0px #181A210A, 0px 60px 120px 0px #181A2114;
  padding: 5px;
  background-color: #F6F6F8;
  color: #181a21;
  border: 1px solid #181a21;
}
