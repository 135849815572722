.reportItem {
  color: #1F1F1F;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #F6F6F8;
  box-shadow: 0px 0px 24px 0px #181A210A, 0px 60px 120px 0px #181A2114;
  margin-bottom: 2px;
  display: block;
  padding: 2px 5px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.name {
  font-weight: 600;
}

.descr {
  color: #848790;
  font-weight: 400;
}