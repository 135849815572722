.button {
  background-color: #00BEB4;
  color: #ffffff;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  text-align: center;
  border: 2px solid #00BEB4;
  margin: 0 auto;
  display: block;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s linear;
}

.button:hover {
  background-color: #ffffff;
  color: #00BEB4;
}