.datepicker {
    padding: 0;
    margin: 0;
    max-width: 200px;
}

@media (max-width: 767px) {
    .datepicker{
        max-width: 100px;
        font-size: 14px;
    }
  }