.td,
.tdRed,
.tdTotal {
  padding: 10px;
  max-width: 1200px;
  min-width: 125px;
  text-align: left;
}

.tdAmount,
.tdTotalAmount {
  padding: 10px;
  max-width: 1200px;
  min-width: 125px;
  text-align: right;
}

.hidden {
  display: none;
}

.tdRed {
  color: #E74C3C;
}

.tdTotal,
.tdTotalAmount {
  font-weight: 600;
}

.th {
  padding: 10px;
  max-width: 1200px;
  min-width: 125px;
  white-space: break-spaces;
}

/* .th:first-child {
  width: 20%;
} */

.tableBox {
  max-width: 1200px;
  margin: 0 auto;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 16px;
  max-width: 1200px;
  position: relative;
}

.button {
  padding: 7px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  max-width: 90px;
}

.button .exportSpan svg path {
  transition: all 0.3s linear;
}

.button:hover .exportSpan svg path {
  fill: #00BEB4;
}

.csv {
  display: block;
  padding: 7px;
  background-color: #00BEB4;
  border: 2px solid #00BEB4;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
  transition: all 0.3s linear;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  max-width: 90px;
}

.csv:hover{
    background-color: #ffffff;
    color: #00BEB4;
}

.csv:hover .exportSpan svg path {
  fill: #00BEB4;
}

.csv .exportSpan svg path {
  transition: all 0.3s linear;
}

.exports {
  display: flex;
  column-gap: 8px;
}

.exportSpan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 16px;
  max-width: 1200px;
  margin: 0 auto 6px;
}

.date {
  color: #848790;
  font-size: 14px;
  line-height: 20px;
}

.td, .tdTotal, .tdTotalAmount {
  background-color: #F6F6F8;
}

.tableBox {
  max-width: 1200px;
  margin: 0 auto;
  height: 62vh;
  overflow-y: auto;
  overflow-x: auto;
}

.td {
  position: sticky;
  left: 0;
}

.th {
  position: sticky;
  top: 0;
  left: 125px;
}

.th:first-child {
  left: 0;
}

.tdTotal {
  position: sticky;
  left: 0;
}

@media (max-width:767px) {
  .tableBox {
    height: 100vh
  }

  th {
    top: 142px;
  }

  .button, .csv {
    padding: 3px 6px;
  }

  .th {
    left: 80px;
  }
}

@media (max-width:520px) {
  .table {
    font-size: 12px;
  }

  .td,
  .th,
  .tdRed,
  .tdTotal,
  .tdAmount,
  .tdTotalAmount {
    padding: 6px;
    min-width: 100px
  }
}

@media (max-width:450px) {
  .table {
    font-size: 12px;
  }

  .td,
  .th,
  .tdRed,
  .tdTotal,
  .tdAmount,
  .tdTotalAmount {
    padding: 3px;
    min-width: 100px
  }
}

@media (max-width:363px) {
  .table {
    font-size: 11px;
  }

  .td,
  .th,
  .tdRed,
  .tdTotal,
  .tdAmount,
  .tdTotalAmount {
    padding: 3px;
  }
}