.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #ffffff;
  padding: 24px 0;
  border-bottom: 1px solid #EBECEE;
}
.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
}
.menu {
  padding: 102px 20px 0 20px;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: transparent;
  transition: left 0.4s linear;
  text-align: center;
  color: #00BEB4;
}
.menuBurgerIsOpen {
  padding: 102px 20px 0 20px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: left 0.4s linear;
  text-align: center;
  color: #00BEB4;
  left: 0;
  z-index: 110;
  background-color: #ffffff;
}
.logo {
  width: 170px;
  height: 30px;
  margin-right: 48px;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nav {
  display: flex;
  justify-content: space-between;
}
.navHidden {
  display: none;
}
