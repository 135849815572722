.pairItem {
    color: #181a21;
    border-radius: 10px;
    border: 1px solid #F6F6F8;
    box-shadow: 0px 0px 24px 0px #181A210A, 0px 60px 120px 0px #181A2114;
    margin-bottom: 2px;
    display: block;
    padding: 2px 5px;
    cursor: pointer;
    transition: all 0.3s linear;
}

.pairItem:hover {
    border: 1px solid #00beb4;
    box-shadow: 0px 1px 8px 2px rgba(34, 60, 80, 0.4);
}

.name {
    font-weight: 700;
}