@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
  font-family: "Noto Sans", sans-serif !important
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  outline: none;
  font-family: "Noto Sans", sans-serif !important
}

a {
  text-decoration: none;
  color: #ffffff;
}

.container {
  padding: 0 20px;
}

body, html {
  overflow-x: hidden;
}
@media(max-width: 600px) {
  .container {
    padding: 0 10px;
  }
}

th, td {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  border-right: 1px solid #EBECEE;
  border-bottom: 1px solid #EBECEE;
}
th:first-child, td:first-child {
  border-left:  1px solid #EBECEE;
}

tr:first-child th {
  border-top:  1px solid #EBECEE;
  border-radius: 8px, 0px, 0px, 0px;
}

tr:first-child th {
  border-top:  1px solid #EBECEE;
}

th {
  font-weight: 600;
}

td {
  font-weight: 400;
}

@media (max-width: 767px) {
  .css-1uvydh2 {
    padding: 5px !important;
  }
  .css-160unip > .MuiTextField-root{
    max-width: 120px !important;
  }
  .css-160unip {
    max-width: 120px !important;
  }
}
