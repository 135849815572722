.arrowBack{
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}

.arrowBack svg{
  display: flex;
  justify-content: center;
  align-items:center;
  width: 30px;
  transition: all 0.3s linear;
  border: 2px solid #00BEB4;
}

.arrowBack svg:hover path {
  fill: #00BEB4;
  stroke: #00BEB4
}