.toggle {
  display: inline-block;
  overflow: hidden;
  /* padding: 8px 0 0; */
  border-bottom: 1px solid #EBECEE;
}

.toggleItem {
  float: left;
  display: inline-block;
}

.toggleItem input[type="radio"] {
  display: none;
}

.toggleItem label {
  padding: 8px 0 13px;
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  width: 100px;
  color: #848790;
  transition: left 0.3s linear;
}

.toggleItem:hover label {
  color: #181A21;
}

.toggleItem input[type="radio"]:checked + label {
  border-bottom: 3px solid #181A21;
  color: #181A21;
}

@media (max-width: 767px) {
  .toggleItem label {
    width: 80px;
  }
}