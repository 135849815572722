.saveVarsItem {
  color: #181a21;
  border-radius: 10px;
  border: 1px solid #181a21;
  margin-bottom: 2px;
  display: block;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s linear;
  max-height: 260px;
}

.h2 {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}

.label {
  margin-right: 10px;
}

.input {
  width: 100%;
  padding: 2px 5px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #F6F6F8;
  display: block;
  transition: all 0.3s linear;
  margin-bottom: 8px;
  border-radius: 10px;
}

.input:focus,
input:active {
  box-shadow: 0px 1px 8px 2px rgba(34, 60, 80, 0.4);
}

.button {
  padding: 10px;
}

.hidden {
  display: none;
}

.notification {
  display: block;
  color: #00BEB4;
  text-align: center;
}

.svgDone {
  display: block;
  margin: 20px auto;
}

.textDone {
  color: #00BEB4;
  font-size: 22px;
  line-height: 26px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .saveVarsItem {
    margin-bottom: 16px;
    padding: 10px;
  }
  .h2 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .label {
    font-size: 14px;
    line-height: 16px;
  }
  .button{
    padding: 8px;
    margin-top: 16px;
  }
}