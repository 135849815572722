.linksBlock {
  margin-bottom: 10px;
  background-color: #ffffff;
  left: 0;
  position: fixed;
  top: 150px;
  width: 100%;
  display: flex;
  transition: all .3s linear;
  column-gap: 50px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.link {
  color: #181a21;
  transition: all 0.3s linear;
}

.link:hover {
  text-decoration: underline;
  color: #00beb4;
}

@media (max-width: 767px) {
  .linksBlock {
    top: 142px
  }
}
