.wrapper {
  margin: 100px auto 0;
}
.text {
  margin-bottom: 50px;
}
.code {
  margin-bottom: 30px;
  font-size: 48px;
  line-height: 56px;
}
.button {
  display: block;
  background-color: #00BEB4;
  color: #ffffff;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  max-width: 360px;
  text-align: center;
  border: none;
  margin: 0 auto;
  display: block;
  margin-top: 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}
