.main {
  background-color: #ffffff;
  padding-top: 106px;
}

.textInfo {
  font-weight: 400;
  margin-top: 30px;
  font-size: 18px;
}

.textInfo p {
  margin-top: 10px;
  text-align: left;
}