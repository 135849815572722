.burger,
.burgerIsOpen {
    display: none;
}

@media (max-width: 767px) {
.burger,
.burgerIsOpen {
    display: block;
    position: fixed;
    top: 39px;
    right: 47px;
    z-index: 30;
    transition: all 0.4s linear;
    cursor: pointer;
}
.burgerIsOpen .line {
  background-color: transparent;
}
.burgerIsOpen .line::after {
  top: 0;
  transform: rotate(-45deg);
}
.burgerIsOpen .line::before {
  top: 0;
  transform: rotate(45deg);
}
.line {
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: #181a21;
    transition: all 0.4s linear;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 110;
}
.line::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: #181a21;
    transition: all 0.4s linear;
    top: -7.5px;
    left: 0;
    z-index: 110;
}
.line::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: #181a21;
    transition: all 0.4s linear;
    top: 7.5px;
    left: 0;
    z-index: 110;
}
}
.menu {
    display: block;
    transition: all 0.4s linear;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding: 102px 20px 0 20px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    text-align: center;
  }
  .menuNone {
    display: none;
    transition: all 0.4s linear;
    left: -100%;
  }

.menu nav {
    display: block;
}