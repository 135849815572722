.li, .liActive {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    text-align: left;
    color: #181a21;
    transition: all 0.3s linear;
    width: calc(100% / 3);
}

.liActive a {
    color: #181a21;
    background-color:#FFFFFF;
    border-radius: 10px;
    padding: 8px;
    transition: all 0.3s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    box-shadow: 0px 0px 6px 0px #00000014;
}

.li a {
    transition: all 0.3s linear;
    color: #848790;
    background-color: #F6F6F8;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
}

.liActive a svg path{
    fill: #181a21;
}

.li a svg path{
    fill: #848790;
}

.li a:visited {
    color: #848790;
}

/* .li a:active {
    color: #00BEB4;
} */

.li:last-child {
    margin-bottom: 0;
}

/* .li:hover a {
    color: #00BEB4 ;
    cursor: pointer;
} */

.li:disabled {
    color: #919191;
}

.li:hover a{
    cursor: pointer;
    background-color: #ebecee;
    border-radius: 10px;
}

@media (max-width:767px){
    .li, .liActive {
        font-size: 15px;
        line-height: 22px;
     }
  }
