.descr {
    font-weight: 700;
    margin: 10px 0px;
}

.p {
    margin-bottom: 8px;
}

.autotest {
    display: flex;
    align-items: center;
}

.statusOK {
    margin-left: 15px;
    color: green
}

.statusFailed {
    color: #E74C3C;
    margin-left: 15px;
}