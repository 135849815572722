.main {
    background-color: #ffffff;
    padding-top: 200px;
    text-align: left;
    margin: 0 auto;
}

.tabs li{
    width: 50% !important
}

.block {
    max-width: 800px;
}

.flex {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
}

@media (max-width: 767px) {
    .flex {
        display: block;
    }
}

@media (min-width: 767px) {
    .svg:hover path {
        fill: #00BEB4;
        stroke: #00BEB4
    }
}