.td,
.tdRed,
.tdTotal,
.tdTotalSum {
  padding: 10px;
  max-width: 1200px;
  min-width: 125px;
  text-align: right;
}

.tdRed {
  color: #E74C3C;
  font-weight: 600;
}

.tdTotal,
.tdTotalSum,
.tdTotalUSD {
  font-weight: 600;
  background-color: #F6F6F8;
}

.th,
.tdCurrencies {
  padding: 10px;
  max-width: 1200px;
  min-width: 125px;
  white-space: break-spaces;
}

.tdCurrencies {
  background-color: #F6F6F8;
}

.tableBox {
  max-width: 1200px;
  margin: 0 auto;
  height: 65vh;
  overflow-x: auto;
  overflow-y: scroll;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  position: relative;
  /* margin: 0 auto; */
  /* table-layout: fixed; */
  font-size: 16px;
  /* max-width: 1200px; */
}

.symbol {
  font-weight: 600;
}

.tdCurrencies:hover {
  color: #00BEB4 !important;
  cursor: pointer;
}

th {
  position: sticky;
  top: 0;
  z-index: 19;
  background-color: #1F1F1F;
  color: #ffffff;
}

th:first-child{
  border-radius: 8px 0 0 0;
}

th:last-child{
  border-radius: 0 8px 0 0;
  z-index: 20;
}

.tdCurrencies {
  /* position: relative; */
  text-align: left;
  left: 0;
  z-index: 19;
  position: sticky;
}

.currencyInfo {
  display: block;
  width: 400px;
  background-color: #F6F6F8;
  border-radius: 12px;
  border: 1px solid #1F1F1F;
  position: absolute;
  top: 0px;
  left: 210%;
  padding: 6px 10px;
  color: #181a21 !important;
  cursor: default !important;
}

.currencyInfoTitle {
  font-weight: 600;
}

.currencyInfoText {
  font-weight: normal;
}

.currencyInfoHidden {
  display: none;
}

.tdTotalUSD {
  text-align: right;
  padding: 10px;
  font-weight: 600;
}

.tdTotalUSD:first-child {
  text-align: left;
  padding: 10px;
  font-weight: 600;
  left: 0;
  z-index: 19;
  position: sticky;
}

.tdTotalSum {
  position: sticky;
  right: 0;
}

.tdTotal:last-child, .th:last-child {
  right: 0;
}

.button {
  padding: 7px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  max-width: 90px;
  border-radius: 12px;
}

.button .exportSpan svg path {
  transition: all 0.3s linear;
}

.button:hover .exportSpan svg path {
  fill: #00BEB4;
}

.csv {
  display: block;
  padding: 7px;
  background-color: #00BEB4;
  border: 2px solid #00BEB4;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
  transition: all 0.3s linear;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  max-width: 90px;
}

.csv:hover {
  background-color: #ffffff;
  color: #00BEB4;
}

.csv:hover .exportSpan svg path {
  fill: #00BEB4;
}

.csv .exportSpan svg path {
  transition: all 0.3s linear;
}

.exports {
  display: flex;
  column-gap: 8px;
}

.exportSpan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.tdTotal {
  left: 125px;
  z-index: 19;
  position: sticky;
}

.th:first-child {
  z-index: 20;
  left: 0;
}

.th:nth-child(2) {
  left: 125px;
  z-index: 20;
}

.errorTr {
  background-color: #ff9999 !important;
}

.flex {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto 6px;
}

.date {
  color: #848790;
  font-size: 14px;
  line-height: 20px
}
.errorTr td{ 
  background-color: #ff9999 !important;
}

@media (max-width: 767px) {
  th {
    top: 0;
    min-width: 80px;
  }

  .td,
  .tdRed,
  .tdTotal,
  .tdTotalSum {
    min-width: 80px;
  }

  .th,
  .tdCurrencies {
    top: 0;
    min-width: 80px;
  }

  .tdTotal {
    left: 80px;
  }

  .th:nth-child(2) {
    left: 80px;
  }
  .button, .csv {
    padding: 3px 6px;
  }
}

@media (max-width: 520px) {
  .table {
    font-size: 12px;
  }

  .td,
  .th,
  .tdRed,
  .tdTotal,
  .tdTotalSum,
  .tdCurrencies,
  .tdTotalUSD {
    padding: 6px;
    min-width: 80px;
  }

  .tdTotal {
    left: 80px;
  }

  .th:nth-child(2) {
    left: 80px;
  }

}

@media (max-width: 450px) {
  .table {
    font-size: 12px;
  }

  .td,
  .th,
  .tdRed,
  .tdTotal,
  .tdTotalSum,
  .tdCurrencies,
  .tdTotalUSD {
    padding: 3px;
    min-width: 80px;
  }

  .currencyInfo {
    width: 250px;
  }

  .tdTotal {
    left: 80px;
  }

  .th:nth-child(2) {
    left: 80px;
  }
}

@media (max-width: 363px) {
  .table {
    font-size: 11px;
  }

  .td,
  .th,
  .tdRed,
  .tdTotal,
  .tdTotalSum,
  .tdCurrencies,
  .tdTotalUSD {
    padding: 3px;
    white-space: break-spaces;
  }
}
