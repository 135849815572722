.main {
  background-color: #ffffff;
  padding-top: 180px;
  text-align: left;
  margin: 0 auto;
}
.block {
  max-width: 800px;
}
.flex {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  padding-top: 8px;
  max-width: 650px;
  margin: 0 auto 8px;
}

.button{
  max-width: 200px;
  margin: 0;
  margin-top: 6px;
}

.tabs li {
  width: 100%;
}

.reportDescBlock{
  max-width: 650px;
  margin: 0 auto;
  cursor: default !important;
}

@media (max-width: 767px) {
  .button{
    max-width: 100px;
    padding: 5px;
  }

  .main {
    padding-top: 150px;
  }

  .flex {
    margin-bottom: 8px;
  }
}