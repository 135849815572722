.main {
  background-color: #ffffff;
  padding-top: 106px;
  height: 100vh;
  overflow-y: hidden;
}

.tr {
  margin-bottom: 16px;
  font-weight: 700;
  border: 2px solid #00beb4;
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 28px;
  FONT-WEIGHT: 700;
}

.totalFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
}

.totalItem {
  min-width: 194px;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #F6F6F8;
  box-shadow: 0px 0px 24px 0px #181A210A, 0px 60px 120px 0px #181A2114;
}

.totalDescr {
  color: #848790;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.totalNum {
  color: #181A21;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

@media (max-width: 767px) {
  .main {
    padding-top: 95px;
    overflow-y: hidden;
  }

  .totalItem {
    min-width: 164px;
    padding: 8px;
  }
}