.main {
  background-color: #ffffff;
  padding-top: 200px;
  text-align: left;
  margin: 0 auto;
}

.name {
  font-weight: 700;
  margin-top: 10px;
}

.label {
  margin-right: 10px;
}

.input {
  border-radius: 10px;
  width: 100%;
  padding: 2px 5px;
  outline: none;
  border: 2px solid #00BEB4;
  display: block;
  transition: all 0.3s linear;
  margin-bottom: 8px;
  border-radius: 10px;
}

.input:focus,
input:active {
  box-shadow: 0px 1px 8px 2px rgba(34, 60, 80, 0.4);
}


.svg,
.svgClicked {
  width: 20px;
  min-width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
  transition: all 0.3s linear;
}

.svgClicked path {
  fill: #00BEB4;
  stroke: #00BEB4
}

.svg path,
.svgClicked path {
  transition: all 0.3s linear;
}

.flex, .flexCurrentRate {
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
}
.flexItem{
  width: 50%;
}
.pairItemList{
  width: 50%;
}
.button{
  max-width: 80px;
  margin: 0;
  padding: 8px;
}
.flexCurrentRate {
  align-items: center;
}

@media (max-width: 767px) {
  .flex {
    display: block;
  }

  .flexItem{
    width: 100%;
  }

  .main {
    padding-top: 140px;
  }
  .pairItemList{
    width: 100%;
  }
}