.list {
  list-style: none;
  width: 100%;
  column-gap: 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link, .linkActive {
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  text-align: center;
  color: #848790;
  display: block;
  position: relative;
  cursor: pointer;
  transition: all 0.3s linear;
}

.linkActive {
  color: #181A21;
}

.link:hover {
  color: #181A21;
}
.link:active {
  color: #181A21;
}
.linkLogout {
  color: #181A21;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  transition: all 0.3s linear;
}

.linkLogout:hover {
  color: #00BEB4;
}

.linkLogout svg path, .linkLogout svg {
  transition: all 0.3s linear;
}

.linkLogout:hover svg path {
  fill: #00BEB4;
}

.linkActive a {
  color: #181A21;
}

@media (max-width: 1024px) {
  /* .list {
    min-width: 112px;
  } */

  .link, .linkActive {
    font-size: 18px;
    line-height: 23px;
  }
}

@media (max-width: 767px) {
  .nav {
    display: none;
  }

  .list {
    list-style: none;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .link, .linkActive {
    padding: 26px 0;
  }

  .linkLogout {
    position:static;
  }
}
